

.react-datepicker {
    border: 0;
    font-family : "Inter"
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    border-radius: 360px;
}

.react-datepicker__day--selected {
    border-radius: 360px;
}

.react-datepicker__header {
    background-color: #fff;
    font-weight: 600;
    border-bottom: 0;
}

.react-datepicker__day-names {
    padding-top: 12px;
}
.react-datepicker__current-month {
    /* padding-top: 4px; */
}

.react-datepicker__day {
    font-family: "Inter";
}

.react-datepicker__navigation-icon {
    transform: scale(0.75);
}